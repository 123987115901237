@import "more_columns";
@import "grid";
@import "table"; // Order is important to let table work with created more_columns

@if (variable_exists(screen-lg-max)) {
  @media (max-width: $screen-lg-max) {
    // Deprecated
    .visible-xl {
      display: none;
    }
  }
}

.img-cover-wrapper {
  background-size: cover;
  background-position: center center;

  .img-cover {
    opacity: 0;
  }
}

.row.border-inner {
  > div + div {
    border-left: 1px solid $color-border;
  }
}
.position-relative {
  position: relative;
}

.progress-bar-text-xs {
  font-size: 80%;
}

.progress-bar-text-sm {
  font-size: 90%;
}

.progress-bar-text-md {
  font-size: 100%;
}

.progress-bar-text-lg {
  font-size: 110%;
}

