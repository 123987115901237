@import 'variables';
@import 'meeva/bootstrap/additions';
@import 'meeva/libs/libs';
@import 'meeva/utils/utils';
@import 'library/daterangepicker/style';

.page-footer {
    overflow: hidden;
}

// Responsive datatables
.dataTables_wrapper .table-responsive {
    width: 100%;
}
.dt-table-wrapper {
    width: 100%;
    overflow-x: auto;
}
.dataTables_filter {
    width: auto;
    margin-right: 6px;
    padding-right: 6px;
    border-right: 1px solid #ccc;
}

table.table.dataTable {
  margin-top: 0 !important;
}

// Datatables search toolbar fix
.dataTables_filter label {
    margin-bottom: 0;
}
.datatable-search {
    display: block;
    float: left;
    margin-right: 5px;
    line-height: 32px;
}
.datatable-length {
    display: block;
    float: right;
    margin-left: 5px;
    line-height: 32px;
}
.datatable-processing,
.datatable-loading {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

// transition for table cells and active state
.table-hover > tbody > tr:hover > td {
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s !important;
}
.table-hover > tbody > tr.active > td {
    background-color: $table-row-active;
}

// table column minimal width
table th.minimal,
table td.minimal {
    width: 1%;
    white-space: nowrap;
}

// table small button height fix
.table tr td .btn-xs {
    display: inline;
    margin: 0 5px 0 0;
    padding: 2px 5px;
    line-height: 16px;
}
.table tr td button.btn-xs {
    margin-top: -3px;
    margin-bottom: -3px;
}
.table tr td .btn-xs:first-child {
    margin-left: -3px;
}
.table tr td .btn-xs:last-child {
    margin-right: -3px;
}

// checkable datatable checkbox height fix
.data-table.has-tickbox tbody tr td .checkbox,
.data-table.has-tickbox tbody tr td .radio {
    margin: -2px 0 -3px -1px;
    padding: 0;
}
.data-table.has-tickbox thead tr th .checkbox,
.data-table.has-tickbox thead tr th .radio {
    margin: -21px 0 -8px 0;
    padding: 0;
}

// dropdown color fixes
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    background-color: #3276b1;
}

// datatable input column
.input-column {
    margin: -2px 0;
}

// fix details control row height
.dataTable tbody .details-control {
    padding-top: 4px;
    padding-bottom: 4px;
}

// form file input full width
input[type='file'].btn.btn-default {
    width: 100%;
}

.data-table-querybuilder-presets {
    float: left;
    margin-top: 9px;

    .select {
        display: inline-block;
    }

    .btn {
        margin-top: 0 !important;
        padding: 0 12px !important;
    }

    select {
        max-width: 100%;
        min-width: 220px;
    }
}

// Form control button fixes
button.form-control {
    text-align: left;
    height: auto;
    min-height: 32px;
}

// Datatable entity select modal
@media (min-width: 768px) {
    .datatable-type-modal {
        width: auto;
        max-width: 75vw;
    }
}

// Progress bar fixes
[data-progressbar-value]:before {
    content: attr(data-progressbar-value) '%';
    left: 0;
}

[data-progressbar-value]:after {
    content: '' !important;
    left: 0;
}

.js-daterange-picker {
    cursor: pointer;
}

.daterangepicker {
    .date-value {
        padding: 0.25rem 0.25rem;
        width: 9rem;
        text-align: center;

        &.active {
            border: 2px solid #357ebd;
        }
    }

    &.compare-range {
        td.in-range {
            background-color: lighten(#ec7f2c, 40%);
        }
        td.active {
            background-color: #ec7f2c;
        }
    }

    .daterangepicker-compare-with {
        input[type="checkbox"] {
            vertical-align: middle;
            position: relative;
            margin: 0;
            bottom: 1px;
        }

        .active {
            border: 2px solid #ec7f2c;
        }
    }
}


/* entire container, keeps perspective */
.flip-container {
    perspective: 1000px;
}

/* flip the pane when hovered */
.flip-container.hover .flipper, .flip-container.flip .flipper {
    transform: rotateY(180deg);
}

.flip-container {
    /* flip speed goes here */
    .flipper {
        transition: 0.6s;
        transform-style: preserve-3d;

        position: relative;
    }

    /* hide back of pane during swap */
    .front, .back {
        backface-visibility: hidden;

        width: 100%;
        height: 100%;
    }

    /* front pane, placed above back */
    .front {
        z-index: 2;
        /* for firefox 31 */
        transform: rotateY(0deg);
    }

    /* back, initially hidden pane */
    .back {
        transform: rotateY(180deg);
        position: fixed;
        top: 0;
    }
}

.select2-container {
    .select2-selection--single {
        .select2-selection__clear {
            background-color: transparent;
            border: none;
            font-size: 1em;
        }
    }
}
.select2-container--default {
    .select2-selection--single {
        .select2-selection__clear {
            display: block;

            font-size: 18px;
            cursor: pointer;
            float: right;
            //font-weight: 700;
            height: 32px;
            margin-right: 34px;
            padding-right: 0;
        }
    }
}
.select2-container {
    &.select2-container--disabled {
        .select2-selection--single {
            .select2-selection__clear {
                display: none;
            }
        }
        .select2-selection--multiple {
            .select2-selection__choice {
                padding-right: 8px;
            }
            .select2-selection__choice__remove {
                display: none;
            }
        }
    }
}

@media (max-width: 1024px) {
    table th.word-wrap-title {
        white-space: break-spaces;
    }
}
