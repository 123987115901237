$color-border: gray !default;

@mixin build-table-cells($i: 1, $list: "> .col-xs-#{$i}, > .col-sm-#{$i}, > .col-md-#{$i}, > .col-lg-#{$i}") {
  // Bad practice to string build selector, but @extend doesn't work within media query
  @for $i from (1 + 1) through $max-grid-columns {
    $list: "#{$list}, > .col-xs-#{$i}, > .col-sm-#{$i}, > .col-md-#{$i}, > .col-lg-#{$i}";
  }
  #{$list} {
    display: table-cell;
    float: none;
  }
  .col-equal {
    display: table-cell;
    width: 1%;
  }
}
@mixin build-table() {
  display: table;
  table-layout: fixed;
  margin-bottom: 0;
  width: 100%;

  > .row {
    display: table-row;
    margin-right: 0;
    margin-left: 0;

    @include build-table-cells()
  }

  &.border-inner {
    > .row + .row {
      > div {
        border-top: 1px solid $color-border;
      }
    }
    > .row {
      > div + div {
        border-left: 1px solid $color-border;
      }
    }
  }
}

// Mobile First
.table-xs {
  @include build-table;
  @media (max-width: $screen-xs-max) {
    .table-xs-only {
      @include build-table();
    }
  }
}
@media (min-width: $screen-sm-min) {
  .table-sm {
    @include build-table;
  }
  @media (max-width: $screen-sm-max) {
    .table-sm-only {
      @include build-table();
    }
  }
}
@media (min-width: $screen-md-min) {
  .table-md {
    @include build-table;
  }
  @media (max-width: $screen-md-max) {
    .table-md-only {
      @include build-table();
    }
  }
}
@media (min-width: $screen-lg-min) {
  .table-lg {
    @include build-table;
  }
}

// Desktop First
@media (max-width: $screen-md-max) {
  .table-not-lg {
    @include build-table;
  }
}
@media (max-width: $screen-sm-max) {
  .table-not-md {
    @include build-table;
  }
}
@media (max-width: $screen-xs-max) {
  .table-not-sm {
    @include build-table;
  }
}

.vertical-top {
  vertical-align: top;
}
.vertical-bottom {
  vertical-align: bottom;
}
.vertical-middle {
  vertical-align: middle;
}

// Bootstrap Table-CSS Addition
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  &.vertical-bottom {
    @extend .vertical-bottom;
  }
  &.vertical-middle {
    @extend .vertical-middle;
  }
}
.table {
  &.border-inner {
    > tr + tr {
      > td, > th {
        border-top: 1px solid $color-border;
      }
    }
    > tr {
      > td + td, > td + th, > th + td, > th + th {
        border-left: 1px solid $color-border;
      }
    }
  }
}
.table-equal {
  display: table;
  width: 100%;
  table-layout: fixed;
  padding: 0;
  > .row {
    display: table-row;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    &:before, &:after {
      display: none;
    }
    .col {
      display: table-cell;
      width: 1%;
    }
  }
  &.border-inner {
    > .row + .row {
      > .col {
        border-top: 1px solid $color-border;
      }
    }
    > .row {
      > .col + .col {
        border-left: 1px solid $color-border;
      }
    }
  }
}

.table-active > tbody > tr:active,
.table-active > tbody > tr.table-active-checked {
  background-color: $table-row-active;
}
