/*
 * FULL CALENDAR
 */
#calendar-container { position: relative; }
	
html .fc, .fc table {	font-size:12px; }
	
.dt-header.calender-spacer {
	height: 46px;
	display: block;
}
	
.jarviswidget #calendar { margin-top: -18px; }
	
.fc-widget-header, .fc-widget-content { border-right: none; }

tr.fc-first th.fc-first,  tr td.fc-first{ border-left:none; }
tr.fc-last td { border-bottom:none; }
	
// update by 25/jan/2016 line no 20 - 34
.jarviswidget .fc-toolbar .fc-left{
	float: none;
}
.jarviswidget .fc-toolbar{
	margin-bottom: 0;
}
.jarviswidget .fc-toolbar h2 {
	text-shadow: 0 1px 0 $white;
	margin-top: -12px;
	margin-left: 10px;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 9px;
}
	
.fc-time, .fc-title {
	padding: 3px 2px 2px 4px;
	line-height: 16px;
	font-weight: bold;
	font-size: 11px;
	box-sizing: border-box;
}
.fc-axis{font-weight: normal;}
	
/* calendar button */
#calendar-buttons {
	position: absolute;
	right: 14px;
	top: 5px;
}

/* other month */
td.fc-other-month, .dropzone {
	background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0.25, rgba(red($black), green($black), blue($black), 0.03)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(red($black), green($black), blue($black), 0.03)), color-stop(0.75, rgba(red($black), green($black), blue($black), 0.03)), color-stop(0.75, transparent), to(transparent));
	background-image: -webkit-linear-gradient(135deg, rgba(red($black), green($black), blue($black), 0.03) 25%, transparent 25%, transparent 50%, rgba(red($black), green($black), blue($black), 0.03) 50%, rgba(red($black), green($black), blue($black), 0.03) 75%, transparent 75%, transparent);
	background-image: -moz-linear-gradient(135deg, rgba(red($black), green($black), blue($black), 0.03) 25%, transparent 25%, transparent 50%, rgba(red($black), green($black), blue($black), 0.03) 50%, rgba(red($black), green($black), blue($black), 0.03) 75%, transparent 75%, transparent);
	background-image: -ms-linear-gradient(135deg, rgba(red($black), green($black), blue($black), 0.03) 25%, transparent 25%, transparent 50%, rgba(red($black), green($black), blue($black), 0.03) 50%, rgba(red($black), green($black), blue($black), 0.03) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(135deg, rgba(red($black), green($black), blue($black), 0.03) 25%, transparent 25%, transparent 50%, rgba(red($black), green($black), blue($black), 0.03) 50%, rgba(red($black), green($black), blue($black), 0.03) 75%, transparent 75%, transparent);
	background-image: linear-gradient(135deg, rgba(red($black), green($black), blue($black), 0.03) 25%, transparent 25%, transparent 50%, rgba(red($black), green($black), blue($black), 0.03) 50%, rgba(red($black), green($black), blue($black), 0.03) 75%, transparent 75%, transparent);
	background-color: #FAFCFD;
	background-size: 16px 16px;
}
	
.fc-corner-right .fc-event-inner { padding-right: 15px; }
	
		
/*
	* Select color
	*/
		
.btn-select-tick i {
	display: none;
}
.btn-select-tick .btn:hover i {
	opacity: .3;
	display: block;
}
		
.btn-select-tick .active i {
	display: block;
	opacity: 1 !important;
}
			
/* 
	* Drag and drop events
	*/
#external-events > li {
	margin: 6px 4px 6px 0;
	display: inline-block;
  >:first-child {
	  padding: 5px 10px 10px;
	  cursor: move;
	  display: block;
    &:after {
	    display: block;
	    color: $white;			
	    color: rgba(red($white), green($white), blue($white),0.7);
	    content: attr(data-description);
	    font-size: 11px;
	    font-weight: 400;
	    display: block;
	    line-height: 0;
	    margin: 7px 0;
	    text-transform: lowercase;
    }
  }
}
		

