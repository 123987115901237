.bootstrap-tagsinput {
  display: block;
  width: 100%;
  min-height: 32px;
  padding: 1px 3px;
  font-size: 13px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #FFF;
  border: 1px solid #CCC;
  border-radius: 0;
  > span {
    border-radius: 0px !important;
    font-weight: normal;
    padding: 3px 28px 4px 8px;
    font-size: 13px;
    border: 1px solid #285E8E;
    background: #3276B1;
  }
  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  .tag {
    color: #FFF;
    position: relative;
    margin: 3px 0 3px 2px;
    display: inline-block;
    [data-role="remove"] {
      display: block;
      top: -1px;
      right: 0px;
      padding: 3px 4px 3px 5px;
      width: 23px;
      height: 22px;
      position: absolute;
      cursor: pointer;
      &:hover { background: rgba(0, 0, 0, 0.3); }
      &:after {
        content: "\f057";
        font-family: 'Font Awesome 6 Free';
        padding: 2px 1px;
        line-height: 17px;
        font-size: 15px;
        text-align: center;
        font-weight: 600;
      }
      &:hover {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        &:active { box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      }
    }
  }
}
