/* Add anything custom here -- mixins, variable overrides. */
/* by adding things here instead of overriding existing .LESS files, it makes merging changes on product updates easier */

$ajax-dropdown-zindex: max($zindex-modal, $fixed-navigation-zindex) + 5;

@media print {
  a[href]:after {
    content: "";
  }

  .sf-toolbar {
    display: none !important;
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }

  #main {
    margin-left: 0 !important;
  }

  #header, #ribbon, #left-panel, #shortcut, .page-footer, .jarviswidget-ctrls, .btn {
    display: none;
  }

  td, th {
    abbr {
      text-decoration: none;

      &[title]:after {
        content: '' !important;
      }
    }
  }
}

#ribbon {
  z-index: 5;
}

.smartadmin-form .collection {
  .add_collection_entry {
    margin-bottom: 10px;
  }

  .well + .well {
    margin-top: 10px;
  }

  .well .remove_collection_entry {
    margin-bottom: 8px;
    margin-right: -12px;
    margin-top: -12px;
    margin-left: 3px;

    +.row {
      clear: both;
    }
  }

  .well .sortable-handle {
    margin-top: -12px;
  }
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: #847a8c;
  text-align: center;
  line-height: 25px;
  display: inline-block;
  color: #ffffff;
}

.login-info a {
  margin-left: 6px;

  .avatar {
    margin-right: 6px;
  }
}
