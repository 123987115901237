/*
 * Custom Radio and Input
 * SETUP
 */			
.checkbox input[type="checkbox"].checkbox + span, 
.checkbox-inline input[type="checkbox"].checkbox + span, 
.radiobox-inline input[type="radio"].radiobox + span, 
.radio input[type="radio"].radiobox + span {
	margin-left: -20px;
}

.form-inline .checkbox input[type="checkbox"].checkbox + span,
.form-inline .radiobox input[type="radio"].radiobox + span {
	margin-left: 0px;
}
.form-inline .checkbox,
.form-inline .radiobox {
	padding-left: 0px;
}

label input[type="radio"].radiobox + span, input[type="checkbox"].checkbox + span {
	vertical-align: middle;
	line-height: 20px;
}

input[type="checkbox"].checkbox + span, input[type="checkbox"].checkbox + span:after, input[type="checkbox"].checkbox + span:before, 
input[type="radio"].radiobox + span, input[type="radio"].radiobox + span:after, input[type="radio"].radiobox + span:before {
	box-sizing: content-box !important;
}

input[type="checkbox"].checkbox, 
input[type="radio"].radiobox {
	visibility: hidden;
	position: absolute;
	z-index: 20;
	width: 18px;
	height: 18px;
}
label input[type="checkbox"].checkbox + span, 
label input[type="radio"].radiobox + span {
	position: relative;
	z-index: 19;
	display: inline-block;
	margin: 0;
	line-height: 17px;
	min-height: 14px;
	min-width: 14px;
	margin-right: 5px;
}	

label input[type="checkbox"].checkbox + span:hover, 
label input[type="radio"].radiobox + span:hover {
	cursor: pointer;
}

label input[type="radio"].radiobox + span:before,
label input[type="checkbox"].checkbox + span:before {
	font-family: 'Font Awesome 6 Free';
	font-size: 12px;
	border-radius: 0;
	content: "\a0"; /* we use '\a0'; to simulate a non-breaking-space (http://milov.nl/7029) */
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	padding: 1px;
	height: 12px;
	line-height: 12px;
	min-width: 12px;
	margin-right: 5px;
	border: 1px solid $gray-mid-light;
	background-color: $gray-lightest;
	font-weight: 600;
	margin-top: -1px;
	
}

/*
 * RADIO
 */

label input[type="radio"].radiobox + span:before {
	content: "\a0"; /* we use '\a0'; to simulate a non-breaking-space (http://milov.nl/7029) */
	border-radius: 50%;
	box-shadow: inset 0px 2px 3px 0px rgba(red($black), green($black), blue($black), .1), 0px 1px 0px 0px rgba(red($white), green($white), blue($white), .2);
}

label input[type="radio"].radiobox:checked + span {
	font-weight: bold;
}

/* DEFAULT COLOR */

label input[type="radio"].radiobox:checked + span:before {
	content: "\f111"; /* circle */
	color: #2e7bcc;
}

/* ORANGE COLOR */

label input[type="radio"].radiobox.style-3:checked + span:before {
	color: $brand-danger;
	border-color: $brand-danger;
}

/* CHROME COLOR */

label input[type="radio"].radiobox.style-2:checked + span:before {
	color: #66665e;
	border-color: #4d90fe;
	font-size: 9px;
}

label input[type="radio"].radiobox.style-2:checked + span {
	font-weight: normal;
}

/* Style 1 Radio */

label input[type="radio"].radiobox.style-1 + span:before {
	font-size: 9px;
	background: $white;
	text-shadow: none;
	box-shadow: none !important;
	border-width: 1px;
	height: 13px;
	line-height: 13px;
	min-width: 13px;
}
label input[type="radio"].radiobox.style-1:checked + span:before {
	font-size: 10px;
	color: $gray-dark;
	border-color: $gray-dark;
	background: $white;
}
label input[type="radio"].radiobox.style-1:checked + span {
	font-weight: normal;
}


/*
 * RADIO:HOVER & ACTIVE STATES
 */

/* DEFAULT:HOVER radio content --> 2022 */
label:hover input[type="radio"].radiobox + span:before,
label:hover input[type="radio"].radiobox:checked + span:before {
	color: #2e7bcc;
	border-color: #2e7bcc;
}

/* ORANGE:HOVER */

label:hover input[type="radio"].radiobox.style-3 + span:before,
label:hover input[type="radio"].radiobox.style-3:checked + span:before {
	color: $brand-danger;
	border-color: $brand-danger;
}

/* CHROME:HOVER */
label:hover input[type="radio"].radiobox.style-2:checked + span:before {
	color: #66665e;
	border-color: #4d90fe;
}	

/* METRO:HOVER */	
label:hover input[type="radio"].radiobox.style-1:checked + span:before {
	color: $gray-dark;
	border-color: $gray-dark;
	background: $white;
}
label:hover input[type="radio"].radiobox.style-1 + span:before {
	color: $gray-dark;
	border-color: $gray-dark;
	background: $white;
}

/* DEFAULT:ACTIVE */

label:active input[type="radio"].radiobox + span:before {
	box-shadow: inset 0px 2px 3px 0px rgba(red($black), green($black), blue($black), .3), 0px 1px 0px 0px rgba(red($white), green($white), blue($white), .4);
}


/*
* RADIO:DISABLE STATES
*/

/* GLOBAL */
label input[type="radio"][disabled].radiobox + span, 
label input[type="radio"][disabled].radiobox:checked + span, 
label:hover input[type="radio"][disabled].radiobox:checked + span:before {
	color: #a7a7a7;
	cursor: not-allowed;
}

label input[type="radio"][disabled].radiobox:checked + span:before {
	content: "\f111";
}

label input[type="radio"][disabled].radiobox:checked + span:before, 
label input[type="radio"][disabled].radiobox + span:before, 
label:active input[type="radio"][disabled].radiobox:checked + span:before,
label:hover input[type="radio"][disabled].radiobox:checked + span:before {
	background: #eee !important;
	border-color: #ccc !important;
	box-shadow: none;
	color: #a7a7a7;
}

/*
 * CHECKBOX
 */
	
label input[type="checkbox"].checkbox + span:before {
	content: "\a0"; /* we use '\a0'; to simulate a non-breaking-space (http://milov.nl/7029) */
	
}
	
label input[type="checkbox"].checkbox:checked + span:before {
	content: "\f00c"; 
}

label input[type="checkbox"].checkbox:checked + span {
	font-weight: bold;
}

/* DEFAULT COLOR */

label input[type="checkbox"].checkbox:checked + span:before {
	color: #2e7bcc;
}

/* RED COLOR */

label input[type="checkbox"].checkbox.style-3:checked + span:before {
	color: $white;
	border-color: $brand-danger;
	background: $brand-danger;
}

/* CHROME */
label input[type="checkbox"].checkbox.style-2 + span:before {
	background: transparent;
	border-color: #c1c1c1;
	box-shadow: none;
}
label input[type="checkbox"].checkbox.style-2:checked + span:before {
	content: "\f00c";
	color: #66665e;
	border-color: #4d90fe;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQ0MEM4RUFEQUE4MTFFMkEwMjM5NzlCRjAxM0UwRjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQ0MEM4RUJEQUE4MTFFMkEwMjM5NzlCRjAxM0UwRjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDQwQzhFOERBQTgxMUUyQTAyMzk3OUJGMDEzRTBGNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDQwQzhFOURBQTgxMUUyQTAyMzk3OUJGMDEzRTBGNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsD/PVAAAAAQSURBVHjaYv7//78vQIABAAlYA05NMPppAAAAAElFTkSuQmCC) $white; /*#fefeec*/
}
label input[type="checkbox"].checkbox.style-2:checked + span {
	font-weight: normal;
}

/* METRO COLOR */

label input[type="checkbox"].checkbox.style-1 + span:before {
	color: $gray-dark;
	background: $white;
	text-shadow: none;
	box-shadow:none !important;
	border-width: 1px;
	height: 12px;
	line-height: 13px;
	min-width: 12px;
}

label input[type="checkbox"].checkbox.style-1:checked + span:before {
	color: $gray-dark;
	border-color: $gray-dark;
}
label input[type="checkbox"].checkbox.style-1:checked + span {
	font-weight: normal;
}

/*
 * CHECKBOX:HOVER & ACTIVE STATES
 */
label:active input[type="checkbox"].checkbox + span:before {
	box-shadow: inset 0px 2px 3px 0px rgba(red($black), green($black), blue($black), .3), 0px 1px 0px 0px rgba(red($white), green($white), blue($white), .4);
}

/* DEFAULT:HOVER */

label:hover input[type="checkbox"].checkbox + span:before {
	color: #2e7bcc;
	border-color: #2e7bcc;
	
}

/* RED:HOVER*/

label:hover input[type="checkbox"].checkbox.style-3 + span:before {
	color: $white;
	border-color: #a90327;
}

/* CHROME:HOVER*/

label:hover input[type="checkbox"].checkbox.style-2 + span:before {
	box-shadow: inset 0px 2px 3px 0px rgba(red($black), green($black), blue($black), .1), 0px 1px 0px 0px rgba(red($white), green($white), blue($white), .2);
}

label:hover input[type="checkbox"].checkbox.style-2:checked + span:before {
	box-shadow: inset 0px 2px 3px 0px rgba(red($black), green($black), blue($black), .3), 0px 1px 0px 0px rgba(red($white), green($white), blue($white), .4);
}

/* METRO:HOVER*/

label:hover input[type="checkbox"].checkbox.style-1 + span:before {
	border-color: $gray-dark;
}

/* DEFAULT:ACTIVE */

label:active input[type="checkbox"].checkbox:checked + span:before {
	color: $white;
	background: #2e7bcc;
	border-color: #2e7bcc;
}

/* RED:ACTIVE */

label:active input[type="checkbox"].checkbox.style-3:checked + span:before {
	color: $brand-danger;
	background: $white;
	border-color: $brand-danger;
}

/* CHROME:ACTIVE */

label:active input[type="checkbox"].checkbox.style-2:checked + span:before {
	color: #66665e;
	background: transparent;
	border-color: #c1c1c1;
	box-shadow: inset 0px 2px 3px 0px rgba(red($black), green($black), blue($black), .3), 0px 1px 0px 0px rgba(red($white), green($white), blue($white), .4);
}
label:active input[type="checkbox"].checkbox.style-2 + span:before {
	color: #66665e;
	background: transparent;
	border-color: #c1c1c1;
	box-shadow: inset 0px 2px 3px 0px rgba(red($black), green($black), blue($black), .3), 0px 1px 0px 0px rgba(red($white), green($white), blue($white), .4);
}

/* METRO:ACTIVE */

label:active input[type="checkbox"].checkbox.style-1:checked + span:before {
	color: $white;
	background: $gray-dark;
	border-color: $gray-dark;
}

/*
 * CHECKBOX:DISABLE STATES
 */
	
/* GLOBAL */
label input[type="checkbox"][disabled].checkbox + span, 
label input[type="checkbox"][disabled].checkbox:checked + span, 
label:hover input[type="checkbox"][disabled].checkbox:checked + span:before {
	color: #a7a7a7;
	cursor: not-allowed;
}

label input[type="checkbox"][disabled].checkbox:checked + span:before {
	content: "\f00c";
	color: #a7a7a7;
}
label input[type="checkbox"][disabled].checkbox.style-2:checked + span:before {
	content: "\f00c";
}

label input[type="checkbox"][disabled].checkbox:checked + span:before, 
label input[type="checkbox"][disabled].checkbox + span:before, 
label:active input[type="checkbox"][disabled].checkbox:checked + span:before,
label:hover input[type="checkbox"][disabled].checkbox:checked + span:before {
	background: #eee !important;
	border-color: #ccc !important;
	box-shadow: none;
	color: #a7a7a7;
	box-shadow: none !important;
}

