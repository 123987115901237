/*
 * JQUI STUFF
 */

/*	Overlay	 */
.ui-widget-overlay {
	background-image: none;
	background: #aaa;
	height: 100%;
	z-index: $ui-widget-overlay !important;
	position: fixed;
	top: 0px;
	left: 0px;
}

/* Slider */
.slider {
	margin-top: 0px;
	margin-bottom: 30px;
	
	&.slider-vertical {
	  margin-bottom: 0px;
	  margin-right: 5px;
  }
}

/* menu */
.ui-menu {
	width: 155px;
	padding: 2px;
	-moz-box-shadow: 0 2px 4px rgba(30,30,100,0.25);
	box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
	background: $white;
	border: 1px solid rgba(red($black), green($black), blue($black), 0.2);
	z-index: 1;
	
	.ui-state-disabled {
	  margin: .4em 0 .2em !important;
	  background: none !important;
	  color: #999 !important;
	  font-weight: normal !important;
	  cursor: default;
  }
  
  .ui-menu-item a .ui-menu-icon {
	  width: auto;
	  height:auto;
	  top: 0px;
	  left: auto;
	  right: auto;
	  bottom: auto;
	  text-indent: 0px;
  }
}

.ui-icon-carat-1-e:before {
	content: "\f105";
	font-family: 'Font Awesome 6 Free';
	font-size: $font-size-base + 1px;
	display: inline;
	font-weight: 600;
}
	
  	
/* tabs */
.ui-tabs .ui-tabs-nav li a {
	border-radius: 0px;
	background-color: #F5F5F5;
	border: 1px solid #F5F5F5;
	border-bottom: 1px solid $nav-tabs-border-color;
	color: #838383;
}
	
.ui-tabs .ui-tabs-nav li {
	margin-right: 3px;
}
	
.ui-tabs .ui-tabs-nav li.ui-tabs-active a {
	font-weight: bold;
	border-top-width: 0px !important;
	margin-top: 1px !important;
	-moz-box-shadow: 0 -2px 0 $blue;
	box-shadow: 0 -2px 0 $blue;
}
	
.ui-tabs .ui-tabs-panel {
	border: 1px solid $nav-tabs-border-color;
	margin: 0;
	padding: 10px 13px;
	border-top: none;
}
	
.ui-tabs .ui-tabs-nav {
	margin-bottom: 0;
}
	
/* accordions */
.ui-accordion .ui-accordion-content {
	padding: 0;
}
	
.ui-accordion .ui-accordion-header {
	background-color: lighten( $gray-lightest, 3%);
	font-size: $font-size-base + 2px;
	padding: 10px 15px 10px 36px !important;
}
	
.ui-accordion .ui-accordion-header, .ui-accordion .ui-accordion-content {
	border-radius: 0px;
	-webkit-border-radius: 0px;
}
.ui-accordion .ui-accordion-content {
	padding: 15px;
}
.ui-accordion-header-active + .ui-accordion-content {
	border-top: 1px solid $nav-tabs-border-color;
}
	
.ui-accordion .ui-accordion-header {
	margin-top: -2px;
}
	
.ui-accordion .ui-accordion-header, .ui-accordion-content {
	border-color: darken($gray-lighter, 7%) !important;
}
	
.ui-accordion-header-icon {
	text-indent: 0px !important;
	background-image: none !important;
	text-align: center;
	line-height: normal;
	left: 12px !important;
	margin-top: -6px !important;
	font-size: 14px !important;
}

/*
 * Calendar
 */
.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-prev {
	text-align: center;
	margin-top: 2px;
}
.ui-datepicker-next >:first-child, .ui-datepicker-prev >:first-child {
	text-indent: 0px !important;
}
.ui-datepicker .ui-datepicker-next span, .ui-datepicker .ui-datepicker-prev span {
	background: none !important;
}

/*
 * JS SLIDER
 * Copied from: js/plugin/noUiSlider/jquery.nouislider.css 
 */

/*	General CSS resets;
 *		The target itself is not affected, allowing
 *		the remainder of the document to use an
 *		alternate box-sizing model;
 *		Support for box-sizing is wide spread:
 *		http://caniuse.com/#search=box-sizing
 */
.noUi-target * {
	box-sizing: border-box;
	cursor: default;
}

/*	Main slider bar;
 *		Standard styles no longer incorrectly force a
 *		width or height on the slider.
 */
.noUi-base {
	width: 100%;
	height: 14px;
	position: relative;
	max-width: 100%;
	max-height: 100%;
	border: 1px solid #bfbfbf;
	z-index: 1;
}
	
.noUi-origin-lower {
	background: lighten($slider-bar-color, 20%);
}

/*	Handles + active state;
 *		The :after pseudo-element wont inherit
 *		box-sizing, so it needs to applied manually.
 */
.noUi-handle {
	background: $handle-bg-color;
	height: 30px;
	width: 16px;
	border: 1px solid $white;
	margin: -8px 0 0 -8px;
	cursor: default;
	&:hover { background: darken($handle-bg-color, 10%); }
}
.noUi-active {
	cursor: default;
	box-shadow: rgba(red($black), green($black), blue($black), 0.1) 1px 1px 1px 0px;
	background: #686868;
	height: 32px;
	width: 18px;
	margin: -9px 0 0 -8px;
	border: none;
	
	&:after {
	  box-sizing: border-box;
	  content: "";
	  display: block;
	  height: 100%;
	  border: 1px solid $nav-tabs-border-color;
  }
}
  
/*	Styling-only classes;
 *		Structured to prevent double declarations
 *		for various states of the slider.
 */
.noUi-connect {
	background: $slider-bar-color;
}
.noUi-background {
	background: $gray-lighter;
	border:none;		
}

/*	Functional styles for handle positioning;
 *		Note that the origins have z-index 0, the base has
 *		z-index 1; This fixes a bug where borders become invisible.
 */
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
}
.noUi-origin-upper {
	background: inherit !important;
}
.noUi-z-index {
	z-index: 10;
}

/*	Adaptations for the vertical slider;
 *		Some standard styles have been extended to keep
 *		exceptions for the vertical slider as minimal as possible.
 */
.noUi-vertical {
	width: 10px;
	height: 100%;
}
.noUi-vertical .noUi-origin {
	bottom: 0;
	left: 0;
}
.noUi-vertical .noUi-handle  {
	margin: -8px 0 0 -3px;
}

/*	Various alternate slider states;
 *		Support for transition is widely available,
 *		Only IE7, IE8 and IE9 will ignore these rules.
 *		Since this is merely a progressive enhancement,
 *		this is no problem at all.
 *		http://caniuse.com/#search=transition
 */
.noUi-target[disabled] .noUi-base {
	background: $gray-light;
}
.noUi-target[disabled] .noUi-connect {
	background: #bbb;
}
.noUi-state-tap .noUi-origin {
	transition: left 0.3s, top 0.3s;
}

/*
 * JQUI SPINNERS
 */
.ui-spinner-input {
	margin: 0 !important;
	height: 30px;
	padding: 6px 24px 6px 12px;
}

.ui-spinner-input.spinner-left {
	padding: 6px 12px 6px 24px;
}

.ui-spinner {
	width: 100%;
}

.ui-spinner-input.spinner-left + .ui-spinner-button, .ui-spinner-input.spinner-left + .ui-spinner-button + .ui-spinner-button{
	right: auto;
	left: 0px;
}
	
.ui-spinner-up, .ui-spinner-down {
	background: $brand-success;
	border-radius: 0px;
}
.ui-spinner-up:hover, .ui-spinner-up:active, .ui-spinner-up:focus {
	background: darken($brand-success, 10%);
}
.ui-spinner-down:hover, .ui-spinner-down:active, .ui-spinner-down:focus {
	background: darken($brand-danger, 10%);
}

.ui-spinner-down {
	background: lighten($brand-danger, 5%);
}

.ui-spinner-up:before, .ui-spinner-down:before {
	display: inline-block;
	font-family: 'Font Awesome 6 Free';
	font-style: normal;
	font-weight: 600;
	line-height: 1;
	-moz-osx-font-smoothing: grayscale;
	
	content: "\f067";
	color: $white;
	font-size: $font-size-base + 1px;
	margin-top: 1px;
	
}
.ui-spinner-down:before {
	content: "\f068";
}

.ui-spinner-up >:first-child, .ui-spinner-down >:first-child {
	display: none;	
}


.ui-spinner-button {
  width: 19px;
}

/* spinner both */
.ui-spinner-input.spinner-both {
	padding: 6px 30px;
}

.ui-spinner-input.spinner-both + .ui-spinner-button + .ui-spinner-button {
	left: 0px;
	right: auto;
}
.ui-spinner-input.spinner-both + .ui-spinner-button, 
.ui-spinner-input.spinner-both + .ui-spinner-button + .ui-spinner-button {
	height: 30px;
	width: 25px;
}

.ui-spinner-input.spinner-both + .ui-spinner-up:before, .ui-spinner-input.spinner-both + .ui-spinner-up + .ui-spinner-down:before {
	margin-top: 8px;
}

/*
 * JQUI ADJUSTMENT
 */	
.ui-dialog {
	box-shadow: 0 5px 15px rgba(red($black), green($black), blue($black), 0.5);
	
	border: 1px solid $gray-light;
	border: 1px solid rgba(red($black), green($black), blue($black), 0.2);
}
 
.widget-header >:first-child {
 	margin: 13px 0;
}
 
.ui-widget-overlay {
 	z-index: 999;
}
 
.ui-dialog .ui-dialog-titlebar {
 	padding: 0 10px;
 	background: $white;
 	border-bottom-color: $gray-lighter;
}
 
.ui-dialog .ui-dialog-title {
 	margin: 0;
}
 
.ui-dialog .ui-dialog-titlebar-close {
 	margin-top: -16px;
	margin-right: 4px;
}
 
.ui-dialog-titlebar-close:before {
 	content: "\f00d";
 	font-family: 'Font Awesome 6 Free';
	font-style: normal;
	font-weight: 600;
	line-height: 1;
	-moz-osx-font-smoothing: grayscale;
	font-size: 13px;
}


.ui-dialog .ui-dialog-buttonpane button {
	margin: 0 .4em 0 0;
}

.ui-dialog .ui-dialog-buttonpane {
	margin-top: 13px;
	padding: 19px 15px 20px;
	text-align: right;
	border-top: 1px solid $gray-lighter;	
}

.ui-autocomplete {
	z-index: $ajax-dropdown-zindex !important;
}

