$max-grid-columns: 12 !default;

.row.grid {
  margin-right: 0;
  margin-left: 0;
}
$selector: (unquote("%placeholder"));
@each $sz in lg md sm xs {
  @for $i from 1 to ($max-grid-columns + 1) {
    $selector: $selector, unquote('.row.grid .col-#{$sz}-#{$i}');
  }
}
#{$selector} {
  padding-left: 0;
  padding-right: 0;
}

.container-grid {
  padding-left: 0;
  padding-right: 0;
}

@each $pad in 5 10 {
  .row.gutter-#{$pad} {
    margin-left: $pad * -1px;
    margin-right: $pad * -1px;
  }
  $selector: (unquote("%placeholder"));
  @each $sz in lg md sm xs {
    @for $i from 1 to $max-grid-columns + 1 {
      $selector: $selector, unquote('.row.gutter-#{$pad} .col-#{$sz}-#{$i}');
    }
  }
  #{$selector} {
    padding-left: $pad * 1px;
    padding-right: $pad * 1px;
  }

  $selector: (unquote("%placeholder"));
  @each $sz in lg md sm xs {
    @for $i from 1 to $max-grid-columns + 1 {
      $selector: $selector, unquote('.row.gutter-#{$pad * 2}-bottom .col-#{$sz}-#{$i}');
    }
  }
  #{$selector} {
    margin-bottom: $pad * 2px;
  }
}