div.dataTables_length label {
  margin: 0;
}

table.dataTable {
  .progress {
    margin-bottom: 0;
  }
}

/* fix datatables spacing in button bar */
div.DTTT_container {
  margin-bottom: 3px;
}

/* fix datatables ColVis button */
button.ColVis_Button {
  height: auto;
  padding: 5px 8px;
}

/* fix Datepicker always overlay main-container */
.jarviswidget {
  z-index: 0;

  &.no-header {
    border-top: 1px solid #C2C2C2;
  }
}
