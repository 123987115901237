@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("~@SmartAdminBundle/public/fonts/summernote.eot?0d0d5fac99cc8774d89eb08b1a8323c4");
  src: url("~@SmartAdminBundle/public/fonts/summernote.eot?#iefix")
      format("embedded-opentype"),
    url("~@SmartAdminBundle/public/fonts/summernote.woff?0d0d5fac99cc8774d89eb08b1a8323c4")
      format("woff"),
    url("~@SmartAdminBundle/public/fonts/summernote.ttf?0d0d5fac99cc8774d89eb08b1a8323c4")
      format("truetype");
}
[class^="note-icon-"]:before,
[class*=" note-icon-"]:before {
  display: inline-block;
  font: normal normal normal 14px summernote;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  speak: none;
}
.note-icon-align-center:before,
.note-icon-align-indent:before,
.note-icon-align-justify:before,
.note-icon-align-left:before,
.note-icon-align-outdent:before,
.note-icon-align-right:before,
.note-icon-align:before,
.note-icon-arrow-circle-down:before,
.note-icon-arrow-circle-left:before,
.note-icon-arrow-circle-right:before,
.note-icon-arrow-circle-up:before,
.note-icon-arrows-alt:before,
.note-icon-arrows-h:before,
.note-icon-arrows-v:before,
.note-icon-bold:before,
.note-icon-caret:before,
.note-icon-chain-broken:before,
.note-icon-circle:before,
.note-icon-close:before,
.note-icon-code:before,
.note-icon-col-after:before,
.note-icon-col-before:before,
.note-icon-col-remove:before,
.note-icon-eraser:before,
.note-icon-font:before,
.note-icon-frame:before,
.note-icon-italic:before,
.note-icon-link:before,
.note-icon-magic:before,
.note-icon-menu-check:before,
.note-icon-minus:before,
.note-icon-orderedlist:before,
.note-icon-pencil:before,
.note-icon-picture:before,
.note-icon-question:before,
.note-icon-redo:before,
.note-icon-row-above:before,
.note-icon-row-below:before,
.note-icon-row-remove:before,
.note-icon-special-character:before,
.note-icon-square:before,
.note-icon-strikethrough:before,
.note-icon-subscript:before,
.note-icon-summernote:before,
.note-icon-superscript:before,
.note-icon-table:before,
.note-icon-text-height:before,
.note-icon-trash:before,
.note-icon-underline:before,
.note-icon-undo:before,
.note-icon-unorderedlist:before,
.note-icon-video:before {
  display: inline-block;
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
.note-icon-align-center:before {
  content: "\f101";
}
.note-icon-align-indent:before {
  content: "\f102";
}
.note-icon-align-justify:before {
  content: "\f103";
}
.note-icon-align-left:before {
  content: "\f104";
}
.note-icon-align-outdent:before {
  content: "\f105";
}
.note-icon-align-right:before {
  content: "\f106";
}
.note-icon-align:before {
  content: "\f107";
}
.note-icon-arrow-circle-down:before {
  content: "\f108";
}
.note-icon-arrow-circle-left:before {
  content: "\f109";
}
.note-icon-arrow-circle-right:before {
  content: "\f10a";
}
.note-icon-arrow-circle-up:before {
  content: "\f10b";
}
.note-icon-arrows-alt:before {
  content: "\f10c";
}
.note-icon-arrows-h:before {
  content: "\f10d";
}
.note-icon-arrows-v:before {
  content: "\f10e";
}
.note-icon-bold:before {
  content: "\f10f";
}
.note-icon-caret:before {
  content: "\f110";
}
.note-icon-chain-broken:before {
  content: "\f111";
}
.note-icon-circle:before {
  content: "\f112";
}
.note-icon-close:before {
  content: "\f113";
}
.note-icon-code:before {
  content: "\f114";
}
.note-icon-col-after:before {
  content: "\f115";
}
.note-icon-col-before:before {
  content: "\f116";
}
.note-icon-col-remove:before {
  content: "\f117";
}
.note-icon-eraser:before {
  content: "\f118";
}
.note-icon-font:before {
  content: "\f119";
}
.note-icon-frame:before {
  content: "\f11a";
}
.note-icon-italic:before {
  content: "\f11b";
}
.note-icon-link:before {
  content: "\f11c";
}
.note-icon-magic:before {
  content: "\f11d";
}
.note-icon-menu-check:before {
  content: "\f11e";
}
.note-icon-minus:before {
  content: "\f11f";
}
.note-icon-orderedlist:before {
  content: "\f120";
}
.note-icon-pencil:before {
  content: "\f121";
}
.note-icon-picture:before {
  content: "\f122";
}
.note-icon-question:before {
  content: "\f123";
}
.note-icon-redo:before {
  content: "\f124";
}
.note-icon-row-above:before {
  content: "\f125";
}
.note-icon-row-below:before {
  content: "\f126";
}
.note-icon-row-remove:before {
  content: "\f127";
}
.note-icon-special-character:before {
  content: "\f128";
}
.note-icon-square:before {
  content: "\f129";
}
.note-icon-strikethrough:before {
  content: "\f12a";
}
.note-icon-subscript:before {
  content: "\f12b";
}
.note-icon-summernote:before {
  content: "\f12c";
}
.note-icon-superscript:before {
  content: "\f12d";
}
.note-icon-table:before {
  content: "\f12e";
}
.note-icon-text-height:before {
  content: "\f12f";
}
.note-icon-trash:before {
  content: "\f130";
}
.note-icon-underline:before {
  content: "\f131";
}
.note-icon-undo:before {
  content: "\f132";
}
.note-icon-unorderedlist:before {
  content: "\f133";
}
.note-icon-video:before {
  content: "\f134";
}
.note-editor {
  position: relative;
}
.note-editor .note-dropzone {
  position: absolute;
  z-index: 100;
  display: none;
  color: #87cefa;
  background-color: white;
  opacity: 0.95;
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.note-editor .note-dropzone.hover {
  color: #098ddf;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-editing-area {
  position: relative;
}
.note-editor .note-editing-area .note-editable {
  outline: 0;
}
.note-editor .note-editing-area .note-editable sup {
  vertical-align: super;
}
.note-editor .note-editing-area .note-editable sub {
  vertical-align: sub;
}
.note-editor .note-editing-area img.note-float-left {
  margin-right: 10px;
}
.note-editor .note-editing-area img.note-float-right {
  margin-left: 10px;
}
.note-editor.note-frame {
  border: 1px solid #a9a9a9;
}
.note-editor.note-frame.codeview .note-editing-area .note-editable {
  display: none;
}
.note-editor.note-frame.codeview .note-editing-area .note-codable {
  display: block;
}
.note-editor.note-frame .note-editing-area {
  overflow: hidden;
}
.note-editor.note-frame .note-editing-area .note-editable {
  padding: 10px;
  overflow: auto;
  color: #000;
  background-color: #fff;
}
.note-editor.note-frame
  .note-editing-area
  .note-editable[contenteditable="false"] {
  background-color: #e5e5e5;
}
.note-editor.note-frame .note-editing-area .note-codable {
  display: none;
  width: 100%;
  padding: 10px;
  margin-bottom: 0;
  font-family: Menlo, Monaco, monospace, sans-serif;
  font-size: 14px;
  color: #ccc;
  background-color: #222;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  resize: none;
}
.note-editor.note-frame.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100% !important;
}
.note-editor.note-frame.fullscreen .note-editable {
  background-color: white;
}
.note-editor.note-frame.fullscreen .note-resizebar {
  display: none;
}
.note-editor.note-frame .note-statusbar {
  background-color: #f5f5f5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.note-editor.note-frame .note-statusbar .note-resizebar {
  width: 100%;
  height: 8px;
  padding-top: 1px;
  cursor: ns-resize;
}
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid #a9a9a9;
}
.note-editor.note-frame .note-placeholder {
  padding: 10px;
}
.note-popover.popover {
  max-width: none;
}
.note-popover.popover .popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.note-popover.popover .arrow {
  left: 20px !important;
}
.note-popover .popover-content,
.panel-heading.note-toolbar {
  padding: 0 0 5px 5px;
  margin: 0;
}
.note-popover .popover-content > .btn-group,
.panel-heading.note-toolbar > .btn-group {
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 0;
}
.note-popover .popover-content .btn-group .note-table,
.panel-heading.note-toolbar .btn-group .note-table {
  min-width: 0;
  padding: 5px;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker,
.panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker {
  font-size: 18px;
}
.note-popover
  .popover-content
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-mousecatcher,
.panel-heading.note-toolbar
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.note-popover
  .popover-content
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-unhighlighted,
.panel-heading.note-toolbar
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}
.note-popover
  .popover-content
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-highlighted,
.panel-heading.note-toolbar
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}
.note-popover .popover-content .note-style h1,
.panel-heading.note-toolbar .note-style h1,
.note-popover .popover-content .note-style h2,
.panel-heading.note-toolbar .note-style h2,
.note-popover .popover-content .note-style h3,
.panel-heading.note-toolbar .note-style h3,
.note-popover .popover-content .note-style h4,
.panel-heading.note-toolbar .note-style h4,
.note-popover .popover-content .note-style h5,
.panel-heading.note-toolbar .note-style h5,
.note-popover .popover-content .note-style h6,
.panel-heading.note-toolbar .note-style h6,
.note-popover .popover-content .note-style blockquote,
.panel-heading.note-toolbar .note-style blockquote {
  margin: 0;
}
.note-popover .popover-content .note-color .dropdown-toggle,
.panel-heading.note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 5px;
}
.note-popover .popover-content .note-color .dropdown-menu,
.panel-heading.note-toolbar .note-color .dropdown-menu {
  min-width: 337px;
}
.note-popover .popover-content .note-color .dropdown-menu .note-palette,
.panel-heading.note-toolbar .note-color .dropdown-menu .note-palette {
  display: inline-block;
  width: 160px;
  margin: 0;
}
.note-popover
  .popover-content
  .note-color
  .dropdown-menu
  .note-palette:first-child,
.panel-heading.note-toolbar
  .note-color
  .dropdown-menu
  .note-palette:first-child {
  margin: 0 5px;
}
.note-popover
  .popover-content
  .note-color
  .dropdown-menu
  .note-palette
  .note-palette-title,
.panel-heading.note-toolbar
  .note-color
  .dropdown-menu
  .note-palette
  .note-palette-title {
  margin: 2px 7px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #eee;
}
.note-popover
  .popover-content
  .note-color
  .dropdown-menu
  .note-palette
  .note-color-reset,
.panel-heading.note-toolbar
  .note-color
  .dropdown-menu
  .note-palette
  .note-color-reset {
  width: 100%;
  padding: 0 3px;
  margin: 3px;
  font-size: 11px;
  cursor: pointer;
  border-radius: 5px;
}
.note-popover
  .popover-content
  .note-color
  .dropdown-menu
  .note-palette
  .note-color-row,
.panel-heading.note-toolbar
  .note-color
  .dropdown-menu
  .note-palette
  .note-color-row {
  height: 20px;
}
.note-popover
  .popover-content
  .note-color
  .dropdown-menu
  .note-palette
  .note-color-reset:hover,
.panel-heading.note-toolbar
  .note-color
  .dropdown-menu
  .note-palette
  .note-color-reset:hover {
  background: #eee;
}
.note-popover .popover-content .note-para .dropdown-menu,
.panel-heading.note-toolbar .note-para .dropdown-menu {
  min-width: 216px;
  padding: 5px;
}
.note-popover .popover-content .note-para .dropdown-menu > div:first-child,
.panel-heading.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 5px;
}
.note-popover .popover-content .dropdown-menu,
.panel-heading.note-toolbar .dropdown-menu {
  min-width: 90px;
}
.note-popover .popover-content .dropdown-menu.right,
.panel-heading.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}
.note-popover .popover-content .dropdown-menu.right::before,
.panel-heading.note-toolbar .dropdown-menu.right::before {
  right: 9px;
  left: auto !important;
}
.note-popover .popover-content .dropdown-menu.right::after,
.panel-heading.note-toolbar .dropdown-menu.right::after {
  right: 10px;
  left: auto !important;
}
.note-popover .popover-content .dropdown-menu.note-check li a i,
.panel-heading.note-toolbar .dropdown-menu.note-check li a i {
  color: deepskyblue;
  visibility: hidden;
}
.note-popover .popover-content .dropdown-menu.note-check li a.checked i,
.panel-heading.note-toolbar .dropdown-menu.note-check li a.checked i {
  visibility: visible;
}
.note-popover .popover-content .note-fontsize-10,
.panel-heading.note-toolbar .note-fontsize-10 {
  font-size: 10px;
}
.note-popover .popover-content .note-color-palette,
.panel-heading.note-toolbar .note-color-palette {
  line-height: 1;
}
.note-popover .popover-content .note-color-palette div .note-color-btn,
.panel-heading.note-toolbar .note-color-palette div .note-color-btn {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
}
.note-popover .popover-content .note-color-palette div .note-color-btn:hover,
.panel-heading.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #000;
}
.note-dialog > div {
  display: none;
}
.note-dialog .form-group {
  margin-right: 0;
  margin-left: 0;
}
.note-dialog .note-modal-form {
  margin: 0;
}
.note-dialog .note-image-dialog .note-dropzone {
  min-height: 100px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 4;
  color: lightgray;
  text-align: center;
  border: 4px dashed lightgray;
}
@-moz-document url-prefix() {
  .note-image-input {
    height: auto;
  }
}
.note-placeholder {
  position: absolute;
  display: none;
  color: gray;
}
.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid black;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle {
  width: 7px;
  height: 7px;
  border: 1px solid black;
}
.note-handle .note-control-selection .note-control-holder {
  width: 7px;
  height: 7px;
  border: 1px solid black;
}
.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  background-color: white;
  border: 1px solid black;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: 0;
  border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: 0;
  border-left: none;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: 0;
  border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.note-handle .note-control-selection .note-control-se.note-control-holder {
  cursor: default;
  border-top: 0;
  border-left: none;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  color: white;
  background-color: black;
  border-radius: 5px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.note-hint-popover {
  min-width: 100px;
  padding: 2px;
}
.note-hint-popover .popover-content {
  max-height: 150px;
  padding: 3px;
  overflow: auto;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item {
  display: block !important;
  padding: 3px;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item.active,
.note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.4;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  background-color: #428bca;
  outline: 0;
}

.note-editor {
  position: relative;
  border: 1px solid #a9a9a9;
  .note-dropzone {
    position: absolute;
    z-index: 1;
    display: none;
    color: #87cefa;
    background-color: white;
    border: 2px dashed #87cefa;
    opacity: 0.95;
    pointer-event: none;
    .note-dropzone-message {
      display: table-cell;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
    }
    &.hover {
      color: #098ddf;
      border: 2px dashed #098ddf;
    }
  }
  &.dragover .note-dropzone {
    display: table;
  }
  .note-toolbar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #a9a9a9;
  }
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    .note-editable {
      background-color: white;
    }
    .note-resizebar {
      display: none;
    }
  }
  &.codeview {
    .note-editable {
      display: none;
    }
    .note-codable {
      display: block;
    }
  }
  .note-statusbar {
    background-color: whitesmoke;
    .note-resizebar {
      width: 100%;
      height: 8px;
      cursor: ns-resize;
      border-top: 1px solid #a9a9a9;
      .note-icon-bar {
        width: 20px;
        margin: 1px auto;
        border-top: 1px solid #a9a9a9;
      }
    }
  }
  .note-editable {
    padding: 10px;
    overflow: auto;
    outline: 0;
    &[contenteditable="false"] {
      background-color: #e5e5e5;
    }
  }
  .note-codable {
    display: none;
    width: 100%;
    padding: 10px;
    margin-bottom: 0;
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    color: #ccc;
    background-color: #222;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    resize: none;
  }
}

.note-air-editor {
  outline: 0;
}

.note-popover .popover {
  max-width: none;
  .popover-content a {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
  .arrow {
    left: 20px;
  }
  .popover-content {
    padding: 0 0 5px 5px;
    margin: 0;
  }
}

.note-toolbar {
  padding: 0 0 5px 5px;
  margin: 0;
}

.note-popover .popover .popover-content > .btn-group,
.note-toolbar > .btn-group {
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 0;
}

.note-popover .popover .popover-content .note-table .dropdown-menu,
.note-toolbar .note-table .dropdown-menu {
  min-width: 0;
  padding: 5px;
}

.note-popover
  .popover
  .popover-content
  .note-table
  .dropdown-menu
  .note-dimension-picker,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker {
  font-size: 18px;
}

.note-popover
  .popover
  .popover-content
  .note-table
  .dropdown-menu
  .note-dimension-picker
  .note-dimension-picker-mousecatcher,
.note-toolbar
  .note-table
  .dropdown-menu
  .note-dimension-picker
  .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}

.note-popover
  .popover
  .popover-content
  .note-table
  .dropdown-menu
  .note-dimension-picker
  .note-dimension-picker-unhighlighted,
.note-toolbar
  .note-table
  .dropdown-menu
  .note-dimension-picker
  .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}

.note-popover
  .popover
  .popover-content
  .note-table
  .dropdown-menu
  .note-dimension-picker
  .note-dimension-picker-highlighted,
.note-toolbar
  .note-table
  .dropdown-menu
  .note-dimension-picker
  .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}

.note-popover .popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
  margin: 0;
}

.note-popover .popover .popover-content .note-color .dropdown-toggle,
.note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 5px;
}

.note-popover .popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
  min-width: 340px;
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 0;
}

.note-popover
  .popover
  .popover-content
  .note-color
  .dropdown-menu
  .btn-group:first-child,
.note-toolbar .note-color .dropdown-menu .btn-group:first-child {
  margin: 0 5px;
}

.note-popover
  .popover
  .popover-content
  .note-color
  .dropdown-menu
  .btn-group
  .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin: 2px 7px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #eeeeee;
}

.note-popover
  .popover
  .popover-content
  .note-color
  .dropdown-menu
  .btn-group
  .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  padding: 0 3px;
  margin: 3px;
  font-size: 11px;
  cursor: pointer;
  border-radius: 5px;
}

.note-popover
  .popover
  .popover-content
  .note-color
  .dropdown-menu
  .btn-group
  .note-color-row,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-row {
  height: 20px;
}

.note-popover
  .popover
  .popover-content
  .note-color
  .dropdown-menu
  .btn-group
  .note-color-reset:hover,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
  background: #eeeeee;
}

.note-popover .popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
  min-width: 216px;
  padding: 5px;
}

.note-popover
  .popover
  .popover-content
  .note-para
  .dropdown-menu
  > div:first-child,
.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 5px;
}

.note-popover .popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
  min-width: 90px;
}

.note-popover .popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}

.note-popover .popover .popover-content .dropdown-menu.right::before,
.note-toolbar .dropdown-menu.right::before {
  right: 9px;
  left: auto !important;
}

.note-popover .popover .popover-content .dropdown-menu.right::after,
.note-toolbar .dropdown-menu.right::after {
  right: 10px;
  left: auto !important;
}

.note-popover .popover .popover-content .dropdown-menu li a i,
.note-toolbar .dropdown-menu li a i {
  color: deepskyblue;
  visibility: hidden;
}

.note-popover .popover .popover-content .dropdown-menu li a.checked i,
.note-toolbar .dropdown-menu li a.checked i {
  visibility: visible;
}

.note-popover .popover .popover-content .note-fontsize-10,
.note-toolbar .note-fontsize-10 {
  font-size: 10px;
}

.note-popover .popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
  line-height: 1;
}

.note-popover .popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: 1px solid white;
}

.note-popover
  .popover
  .popover-content
  .note-color-palette
  div
  .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid black;
}

.note-dialog {
  > div {
    display: none;
  }
  .note-image-dialog .note-dropzone {
    min-height: 100px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 4;
    color: lightgray;
    text-align: center;
    border: 4px dashed lightgrey;
  }
  .note-help-dialog {
    font-size: 12px;
    color: #ccc;
    background: transparent;
    background-color: #222 !important;
    border: 0;
    opacity: 0.9;
    filter: alpha(opacity = 90);
    .modal-content {
      background: transparent;
      border: 1px solid white;
      border-radius: 5px;
      box-shadow: none;
    }
    a {
      font-size: 12px;
      color: white;
    }
    .title {
      padding-bottom: 5px;
      font-size: 14px;
      font-weight: bold;
      color: white;
      border-bottom: white 1px solid;
    }
    .modal-close {
      font-size: 14px;
      color: #dd0;
      cursor: pointer;
    }
    .note-shortcut-layout {
      width: 100%;
      td {
        vertical-align: top;
      }
    }
    .note-shortcut {
      margin-top: 8px;
      th {
        font-size: 13px;
        color: #dd0;
        text-align: left;
      }
      td:first-child {
        min-width: 110px;
        padding-right: 10px;
        font-family: "Courier New";
        color: #dd0;
        text-align: right;
      }
    }
  }
}

.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid black;
  > div {
    position: absolute;
  }
  .note-control-selection-bg {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    filter: alpha(opacity = 30);
  }
  .note-control-handle,
  .note-control-holder {
    width: 7px;
    height: 7px;
    border: 1px solid black;
  }
  .note-control-sizing {
    width: 7px;
    height: 7px;
    background-color: white;
    border: 1px solid black;
  }
  .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: 0;
    border-bottom: 0;
  }
  .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: 0;
    border-left: none;
  }
  .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: 0;
    border-right: 0;
  }
  .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize;
  }
  .note-control-selection-info {
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    color: white;
    background-color: black;
    border-radius: 5px;
    opacity: 0.7;
    filter: alpha(opacity = 70);
  }
}

/* adjustment */

.note-editor .note-editable {
  background-color: rgba(48, 126, 204, 0.05);
}
.note-editor .note-editable:focus {
  background-color: #fff;
}
.note-editor .note-statusbar .note-resizebar {
  border-top: 1px solid #dbdbdb;
}
.note-editor .note-statusbar:hover {
  background: #eee;
}
.note-editor .note-statusbar:active {
  background: #eaeaea;
}
