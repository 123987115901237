body:after {
  display: none;
  content: '{\
    "screen-xs-min": "'+$screen-xs-max+'",\
    "screen-sm-min": "'+$screen-sm-min+'",\
    "screen-md-min": "'+$screen-md-min+'",\
    "screen-lg-min": "'+$screen-lg-min+'",\
    "screen-xs-max": "'+$screen-xs-max+'",\
    "screen-sm-max": "'+$screen-sm-max+'",\
    "screen-md-max": "'+$screen-md-max+'"\
  }';
}
#responsive-breakpoints {
  display: none;
  font-family: '{\
    "screen-xs-min": "'+$screen-xs-max+'",\
    "screen-sm-min": "'+$screen-sm-min+'",\
    "screen-md-min": "'+$screen-md-min+'",\
    "screen-lg-min": "'+$screen-lg-min+'",\
    "screen-xs-max": "'+$screen-xs-max+'",\
    "screen-sm-max": "'+$screen-sm-max+'",\
    "screen-md-max": "'+$screen-md-max+'"\
  }';
}
