.dt-toolbar {
  display: block;
  position: relative;
  padding: 6px 2px 6px 6px;
  width: 100%;
  float: left;
  border-bottom: 1px solid #ccc;
  background: #fafafa;
}
.dt-toolbar-footer {
  background: #fafafa;
  font-size: 11px;
  overflow: hidden;
  padding: 5px 10px;
  border-top: 1px solid #ccc;
  box-shadow: inset 0 1px #fff;
}
.dt-toolbar, .dt-toolbar-footer {
  >:first-child { padding-left: 0px !important; }
  >:last-child { padding-right: 0px !important; }
}

table.dataTable {
  thead {
    .sorting { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAgMAAAAPPt5fAAAACVBMVEUAAADIyMjl5eVIBBP/AAAAAXRSTlMAQObYZgAAAClJREFUCNdjwAYEwGQKiGDsBJFsTA5AUoJhAqZaiDhEDVg9RC/MHEwAANsMA91AQfd/AAAAAElFTkSuQmCC) no-repeat center right; }
    .sorting_asc { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAQMAAABInqSPAAAABlBMVEUAAABVVVUT3Vn+AAAAAXRSTlMAQObYZgAAAB1JREFUCNdjwAkcgPgBAwPjBwYG5h8MDOx/GAgBAKRJBBCQbHkwAAAAAElFTkSuQmCC) no-repeat center right; }
    .sorting_desc { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAQMAAABInqSPAAAABlBMVEUAAABVVVUT3Vn+AAAAAXRSTlMAQObYZgAAABxJREFUCNdjIATY/zAwMP9gYGD8AOQ8AGIHnEoBkNkEEEbbutQAAAAASUVORK5CYII=) no-repeat center right; }
    .sorting_asc_disabled { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAQMAAABInqSPAAAABlBMVEUAAABUVFR8AzIeAAAAAnRSTlMAf7YpoZUAAAAdSURBVAjXY8AJHID4AQMD4wcGBuYfDAzsfxgIAQCkSQQQkGx5MAAAAABJRU5ErkJggg==) no-repeat center right; }
    .sorting_desc_disabled { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAQMAAABInqSPAAAABlBMVEUAAABUVFR8AzIeAAAAAnRSTlMAf7YpoZUAAAAcSURBVAjXYyAE2P8wMDD/YGBg/ADkPABiB5xKAZDZBBBG27rUAAAAAElFTkSuQmCC) no-repeat center right; }
    .sorting_asc, .sorting_desc { background-color: rgb(238, 238, 238); }
    > tr {
      > th.hasinput { padding: 6px !important; }
      > th.sorting_disabled { padding-right: 9px !important; }
    }
  }
  thead, tfoot {
    > tr {
      > th {
        padding-left: 9px !important;
        input {
          width: 100% !important;
          font-weight: normal;
        }
      }
    }
  }
}

.dataTables_empty {
  padding: 20px 10px !important;
  font-size: 14px;
  text-align: center;
  color: #575757;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  padding: 8px 10px;
}

div.dataTables_info {
  padding-top: 9px;
  font-size: 13px;
  font-weight: bold;
  font-style: italic;
  color: #969696;
}

/*
* Double lines in thead tfoot
*/
.dataTable thead>tr>th {
  border-left:none !important;
}

/* checkbox */
.dataTable input[type=checkbox].checkbox,
.dataTable input[type=radio].radiobox {
  left: -999px;
}

/*
* DT FIlter
*/
.dataTables_filter {
  float:left;
  width:100%;
  .input-group-addon {
    width: 32px;
    margin-top: 0px;
    float: left;
    height: 32px;
    padding-top: 8px;
    + .form-control {
      float:left;
    }
  }
}

/*
* LTR filter position
*/
.dt-toolbar {
  >:first-child {
    .dataTables_filter >:only-child, .DTTT, .pagination, .dataTables_length { float: left !important; }
  }
  >:last-child {
    .dataTables_filter >:only-child, .DTTT, .pagination, .dataTables_length { float: right; }
  }
}

/* bottom portion */
.dt-toolbar-footer {
  >:first-child {
    .dataTables_filter >:only-child, .DTTT, .pagination, .dataTables_length { float: left; }
  }
  >:last-child {
    .dataTables_filter >:only-child, .DTTT, .pagination, .dataTables_length { float: right; }
  }
}

/*
* PRINT
*/

body.DTTT_Print { background:#fff !important; }
.DTTT_Print #main { margin:0px !important; }
.DTTT_PrintMessage {
  font-size: 20px;
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  font-weight: bold;
}
.DTTT_Print table.dataTable { margin-top:0px !important; }
.DTTT_Print .dataTables_wrapper table { border: 1px solid #C7C7C7 !important; }

/*
* COLUMN SHOW/HIDE
*/
div.ColVis { margin-bottom:0px !important; }
ul.ColVis_collection {
  width: 127px;
  span { vertical-align: 3px; }
  label { margin-bottom:0px !important; }
}

/*
 * Processing Fix
 */

.dataTables_processing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  text-align: center;
  font-size: 1.5em;
  z-index: 2;
  background: rgba(204, 204, 204, 0.75);
}

/* custom font icon */
.dataTable tbody .details-control{
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.dataTable tbody .details-control:before{
  font-family: 'Font Awesome 6 Free';
  content: "\f055";
  font-size: 1.4em;
  font-weight: 600;
  color:$brand-success;
}
.dataTable tbody .shown .details-control:before{
  content: "\f056";
   color:$brand-danger;
}

.dataTable tbody .shown + tr > td {
  padding:0px !important;
}

.dataTable tbody .shown {
  background: rgba(205, 209, 98, 0.2) !important;
}

.dataTable tbody .shown > td {
  background:none !important;
}

.dataTable tbody .shown + tr > td > table > tbody > tr:last-child{
  border-bottom: 1px solid rgb(194, 194, 194);
}

.dataTable tbody .shown + tr > td > table tbody tr {
  background-color: rgba(205, 209, 98, 0.05) !important;
}


.table-bordered .progress {
  background:#fff !important;
  box-shadow: 0 1px 0 transparent,0 0 0 1px #D6D6D6 inset;
}

/* datatable scroll-y bug fix for firefox */

.dataTables_scroll .dataTables_scrollHead,
.dataTables_scroll .dataTables_scrollBody,
.dataTables_scroll + .dt-toolbar-footer {
  float:left;
}
.dataTables_scroll + .dt-toolbar-footer {
  width:100%;
}

.dataTables_scroll .dataTables_scrollHeadInner {
  margin-top:-6px;
}

.dataTables_scroll .dataTables_scrollHead {
  background: rgb(244, 244, 244);
}
