@use "sass:math";

@import "mixins";
@import "focal-point";
@import "javascript-vars";

@function strip-units($value) {
  @return math.div($value, $value * 0 + 1);
}

.height-100 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.table-md > .row {
  &:after, &:before {
    content: none;
  }
}

/**
 * Hide Block without making it :hidden for jQuery
 */
.no-display {
  opacity: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}