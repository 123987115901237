div {
  &.dataTables_length {
    label {
      font-weight: normal;
      float: left;
      text-align: left;
    }
    select {
      width: 75px;
    }
  }
  &.dataTables_filter {
    label {
      font-weight: normal;
      float: right;
    }
    input {
      width: 16em;
    }
  }
  &.dataTables_info {
    padding-top: 8px;
  }
  &.dataTables_paginate {
    float: right;
    margin: 0;
    ul.pagination {
      margin: 2px 0;
      white-space: nowrap;
    }
  }
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  td, th {
    box-sizing: content-box;
  }
  thead {
    .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled { cursor: pointer; }
    .sorting { background: url("#{$base-url}/datatables/sort_both.png") no-repeat center right; }
    .sorting_asc { background: url("#{$base-url}/datatables/sort_asc.png") no-repeat center right; }
    .sorting_desc { background: url("#{$base-url}/datatables/sort_desc.png") no-repeat center right; }
    .sorting_asc_disabled { background: url("#{$base-url}/datatables/sort_asc_disabled.png") no-repeat center right; }
    .sorting_desc_disabled { background: url("#{$base-url}/datatables/sort_desc_disabled.png") no-repeat center right; }
    > tr > th {
      padding-left: 18px;
      padding-right: 18px;
    }
  }
  th:active {
    outline: none;
  }
}

/* Scrolling */
div.dataTables_scrollHead {
  table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    thead tr:last-child th:first-child, thead tr:last-child td:first-child {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

div.dataTables_scrollBody {
  table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  tbody tr:first-child th, tbody tr:first-child td { border-top: none; }
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

/* Frustratingly the border-collapse:collapse used by Bootstrap makes the column
 width calculations when using scrolling impossible to align columns. We have
 to use separate
*/
table.table-bordered {
  &.dataTable {
    border-collapse: separate !important;
  }
  thead th, thead td {
    border-left-width: 0;
    border-top-width: 0;
  }
  tbody th, tbody td {
    border-left-width: 0;
    border-bottom-width: 0;
  }
  th:last-child, td:last-child { border-right-width: 0; }
}
div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

/*
* TableTools styles
*/

/* conflicting styles with bootstrap
.table tbody tr.active td,
.table tbody tr.active th {
  background-color: #08C;
  color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
  background-color: #0075b0;
}

.table tbody tr.active a {
  color: white;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
  background-color: #017ebc;
} */

table.DTTT_selectable tbody tr {
  cursor: pointer;
}

div.DTTT .btn {
  color: #333 !important;
  font-size: 12px;
  &:hover {
    text-decoration: none !important;
  }
}

ul.DTTT_dropdown.dropdown-menu {
  z-index: 2003;
  a { color: #333 !important; /* needed only when demo_page.css is included */ }
  li {
    position: relative;
    &:hover a {
      background-color: #0088cc;
      color: white !important;
    }
  }
}

div.DTTT_collection_background { z-index: 2002; }
/* TableTools information display */
div.DTTT_print_info {
  &.modal {
    height: 150px;
    margin-top: -75px;
    text-align: center;
  }
  h6 {
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    margin: 1em;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
}

/*
* FixedColumns styles
*/
div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
div.DTFC_RightHeadWrapper table,
div.DTFC_RightFootWrapper table,
table.DTFC_Cloned tr.even {
  background-color: white;
  margin-bottom: 0;
}

div.DTFC_RightHeadWrapper table ,
div.DTFC_LeftHeadWrapper table {
  margin-bottom: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.DTFC_RightHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_RightHeadWrapper table thead tr:last-child td:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.DTFC_RightBodyWrapper table,
div.DTFC_LeftBodyWrapper table {
  border-top: none;
  margin: 0 !important;
}

div.DTFC_RightBodyWrapper tbody tr:first-child th,
div.DTFC_RightBodyWrapper tbody tr:first-child td,
div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
  border-top: none;
}

div.DTFC_RightFootWrapper table,
div.DTFC_LeftFootWrapper table {
  border-top: none;
}


/*
* FixedHeader styles
*/
div.FixedHeader_Cloned table { margin: 0 !important }
