@use "sass:math";

@mixin make-grid-columns-more($col, $sz) {
  @for $i from 1 to ($col + 1) {
    $width: math.div(100, $col) * $i;
    > .col-#{$sz}-#{$i} {
      width: $width*1%;
      float: left;
    }
    > .col-#{$sz}-offset-#{$i} {
      margin-left: $width*1%;
    }
  }
}

@each $col in 15 24 {
  .row.column-#{$col} {
    @include make-grid-columns-more($col, xs);
    @media (min-width: $screen-sm-min) {
      @include make-grid-columns-more($col, sm);
    }
    @media (min-width: $screen-md-min) {
      @include make-grid-columns-more($col, md);
    }
    @media (min-width: $screen-lg-min) {
      @include make-grid-columns-more($col, lg);
    }
  }
}
