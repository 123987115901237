/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
* ColVis styles
*/
div.ColVis {
  float: right;
  margin-bottom: 1em;
}

button.ColVis_Button,
ul.ColVis_collection li {
  position: relative;
  float: left;
  margin-right: 3px;
  padding: 5px 8px;
  border: 1px solid #999;
  cursor: pointer;
  *cursor: hand;
  font-size: 0.88em;
  color: black !important;
  white-space: nowrap;

          border-radius: 2px;

          box-shadow: 1px 1px 3px #ccc;

  /* Generated by http://www.colorzilla.com/gradient-editor/ */
  background: #ffffff; /* Old browsers */
  background:         linear-gradient(to top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=0 ); /* IE6-9 */
}

.ColVis_Button:hover,
ul.ColVis_collection li:hover {
  border: 1px solid #666;
  text-decoration: none !important;

          box-shadow: 1px 1px 3px #999;

  background: #f3f3f3; /* Old browsers */
  background:         linear-gradient(to top, #f3f3f3 0%,#e2e2e2 89%,#f4f4f4 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#f4f4f4',GradientType=0 ); /* IE6-9 */
}

button.ColVis_Button {
  height: 30px;
  padding: 3px 8px;
  &:active { outline: none; }
}
button.ColVis_Button::-moz-focus-inner {
  border: none !important;
  padding: 0;
}

div.ColVis_collectionBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 1100;
}

ul.ColVis_collection {
  width: 150px;
  padding: 8px 8px 4px 8px;
  margin: 0;
  border: 1px solid #ccc;
  border: 1px solid rgba( 0, 0, 0, 0.4 );
  background-color: #f3f3f3;
  background-color: rgba( 255, 255, 255, 0.3 );
  overflow: hidden;
  z-index: 2002;

          border-radius: 5px;

          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  li {
    position: relative;
    height: auto;
    left: 0;
    right: 0;
    padding: 0.5em;

    display: block;
    float: none;
    margin-bottom: 4px;

            box-shadow: 1px 1px 3px #999;

    text-align: left;
    &.ColVis_Button:hover {
      border: 1px solid #999;
      background-color: #f0f0f0;
    }
  }
}

ul.ColVis_collection li {
  span {
    display: inline-block;
    padding-left: 0.5em;
    cursor: pointer;
  }
  &.ColVis_Special {
    border-color: #555;
    background: rgb(237,237,237); /* Old browsers */
    background:         linear-gradient(to bottom, rgba(237,237,237,1) 0%,rgba(214,214,214,1) 77%,rgba(232,232,232,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#e8e8e8',GradientType=0 ); /* IE6-9 */
    &:hover {
      background: #e2e2e2; /* Old browsers */
      background:         linear-gradient(to top, #d0d0d0 0%,#d5d5d5 89%,#e2e2e2 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#e2e2e2',GradientType=0 ); /* IE6-9 */
    }
  }
}

span.ColVis_radio {
  display: inline-block;
  width: 20px;
}

div.ColVis_catcher {
  position: absolute;
  z-index: 1101;
}

.disabled { color: #999; }
