.project-members a {
  display: inline-block;
  overflow: hidden;
  margin: 0 3px 0 0;
  border-radius: 0px;
}
.smart-rtl.project-members a {
  margin: 0 0 0 3px;
}

.project-members img {
  width: 25px;
  border-radius: 0px;
}

.projects-table > tbody>tr>td {
  padding: 7px 10px 3px !important;
}

