$paceHeight: 2px;

.pace {
  pointer-events: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  transform: translate3d(0, -$paceHeight, 0);
  transition: transform .5s ease-out;

  background: $smart-navbar-color-start;
  z-index: $pace-zindex;
}

.pace {
  .pace-progress {
    display: block;
    position: fixed;
    z-index: $pace-zindex;
    top: 0;
    right: 100%;
    width: 100%;
    height: $paceHeight;
    background: $brand-info;
    pointer-events: none;
    transition: transform .5s ease-in-out;
  }

  .pace-activity {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: fixed;
    z-index: $pace-zindex;
    top: 63px;
    right: 40px;
    width: 14px;
    height: 14px;
    border: solid 2px transparent;
    border-top-color: $brand-info;
    border-left-color: $brand-info;
    border-radius: 10px;
    animation: pace-spinner 400ms linear infinite;

    .smart-rtl & {
      left: 40px;
      right: auto;
    }
  }
}

.pace-active {
  transform: translate3d(0, 0, 0);

  .pace-activity {
    opacity: 1;
  }
}

@keyframes pace-spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
