/*
	Important this file is beeen modified by Webarch
*/

/*------------------------------------*\
    SuperBox
\*------------------------------------*/
.superbox *,
.superbox *:after,
.superbox *:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* SuperBox */
.superbox-list {
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 12.5%;
  padding: 5px;
  position: relative;
  &.active:before {
    content: "\f0d8";
    font-family: 'Font Awesome 6 Free';
    font-weight: 600;
    display: block;
    position: absolute;
    left: 43%;
    bottom: -34px;
    z-index: 2;
    text-align: center;
    font-size: 45px;
    color: $gray-darker;
  }
}

.superbox-img {
  max-width: 100%;
  width: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.superbox-show {
  text-align: left;
  position: relative;
  background-color: $gray-darker;
  width: 100%;
  float: left;
  padding: 25px 0 12px 25px;
  display: none;
  margin: 5px 0;
}
.superbox-current-img {
  max-width: 100%;
  float: left;
  padding-right: 25px;
  padding-bottom: 13px;
}
.superbox-float {
  float: left;
}
.superbox-close {
  opacity: 0.7;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: all 0.1s linear 0s;
  &:hover {
    opacity: 1;
  }
}

.superbox-imageinfo {
  display: inline-block;
  max-width: 500px;
  color: #fff;
  padding: 0 25px 0 0;
  span {
    font-size: 13px;
    color: lighten($asideColor-start, 45%);
    margin-right: -2px;
    padding-top: 10px;
    display: inline-block;
    padding-bottom: 13px;
    p:last-child {
      margin-top: 30px;
    }
  }

  >:first-child {
    margin-bottom: 10px;
    font-weight: bold;
  }
}


@media only screen and (min-width: 320px) {
  .superbox-list {
    width:50%;
  }
}
@media only screen and (min-width: 486px) {
  .superbox-list {
    width:25%;
  }
}
@media only screen and (min-width: 768px) {
  .superbox-list {
    width:16.66666667%;
  }
}
@media only screen and (min-width: 1025px) {
  .superbox-list {
    width:12.5%;
  }
}
@media only screen and (min-width: 1824px) {
  .superbox-list {
    width:12.5%;
  }
}
