:fullscreen { /* spec */
  /* properties */
	 #fullscreen > :first-child > a {
		background-color: #e8e8e8;
		background-image: linear-gradient(to bottom, #5A5A5A 0%,#686868 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5A5A5A', endColorstr='#686868',GradientType=0 );
		box-shadow: inset 0px 0px 3px 1px rgba(red($black), green($black), blue($black), .15);
		color: $white;
		border-color: #494949;
	 }
	video {
		width: 100%;
		height: 100%;
	}
}

/* styling the backdrop*/
::backdrop {
  /* properties */
}
::-ms-backdrop {
  /* properties */
}

