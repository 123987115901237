/*
 * FORUM PAGE
 */	
.table-forum {
  thead tr {
	  background: none !important;
    th {
	    font-size: $font-size-base + 4;
	    font-weight: normal;
	    border-bottom-color: #f1f1f1;
    }
  }
  tr td {
    border-top-style: dashed !important; 
    padding-top: 12px !important;
    padding-bottom: 12px !important;

    h4 {
	    font-size: $font-size-base + 2;
	    font-weight: 700;
      small {
	      font-size: $font-size-base;
	      display: block;
	      margin-top: 2px;
      }
    }
    > i {
	    margin-top: 4px;
	    padding-left: 10px;
    }  
  }
  tr.closed h4 > a {
    &:before {
	    display: inline-block;
	    font-family: 'Font Awesome 6 Free';
	    font-style: normal;
	    font-weight: 600;
	    line-height: 1;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    content: "\f023";
    }
	  text-decoration:line-through;
	  color: #848484;
	  cursor: not-allowed;
  }
}

.forum-attachment {
	margin-top: 20px;
	display: block;
}

