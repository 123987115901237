@use "sass:math";

$color-border: grey !default;
$max-grid-columns: 12 !default;

.reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    padding: 0;
    margin: 0;
  }
}

@mixin reset-list($self:false) {
  @if ($self) {
    @extend .reset-list;
  }

  ul, ol {
    @extend .reset-list;
  }
}

@mixin std-raw($width, $height: null) {
  padding-left: $width;
  padding-right: $width;

  @if ($height != null) {
    padding-top: $height;
    padding-bottom: $height;
  }
}

@mixin std($width, $height: null, $i: 1, $list: "> .col-xs-#{$i}, > .col-sm-#{$i}, > .col-md-#{$i}, > .col-lg-#{$i}") {
  .std {
    @include std-raw($width, $height);
  }

  .row.gutter-std {
    margin-left: math.div($width, -2);
    margin-right: math.div($width, -2);

    @for $i from (1 + 1) through $max-grid-columns {
      $list: "#{$list}, > .col-xs-#{$i}, > .col-sm-#{$i}, > .col-md-#{$i}, > .col-lg-#{$i}";
    }

    #{$list} {
      padding-left: $width * 0.5;
      padding-right: $width * 0.5;
    }
  }
}
