@import "daterangepicker";

input.daterangeinput {
  &.active {
    background-color: #ebf4f8;
  }
}

.calendar {
  .daterangepicker_input {
    display: none;
  }
}

.daterangepicker {
  td.off.in-range {
    background-color: transparentize(#ebf4f8, 0.7)
  }

  td.off.start-date, td.off.end-date {
    background-color: transparentize(#357ebd, 0.7);
  }
}

@media (min-width: 564px) {
  .daterangepicker.ltr .ranges {
    float: right;
  }
}

@media (min-width: 730px) {
  .daterangepicker.ltr .ranges {
    float: right;
  }
}
